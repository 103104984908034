import React, { useState } from "react";
import BIMObjectCreation from "../../components/_Landing/BIMObjectCreation";
import SEO from "../../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <BIMObjectCreation />;
};

export default Services;

export const Head = () => (
  <SEO
    title={"BIM Services in Singapore | From CAD to BIM to Build"}
    description="Need to create BIM models in Revit or ArchiCAD from CAD drawings? We provide BIM object creation services for manufacturers, architects, and contractors."
  />
);
